import React from 'react';
import { createRoot } from 'react-dom/client';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { App } from './App';

const onload = (): void => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(new URL('../public/service-worker.js', import.meta.url)).then(() => {
            console.log('service-worker.js registered.');
        }, (err) => {
            console.log('failed to register service-worker.js');
        });
    }
    const ele = window.document.getElementById('codespan');
    const root = createRoot(ele!);
    root.render(
        <App />
    );
};

window.onload = onload;
import React, { Component, MouseEvent, ReactNode } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { RelationshipTypes } from './IdeaModel';

const options = [
  RelationshipTypes['unknown'],
  RelationshipTypes['child'],
  RelationshipTypes['sibling'],
  RelationshipTypes['parent']
];

export interface IdeaRelationshipMenuPickerProps {
  idPrefix: string;
  label: string;
  selected: RelationshipTypes;
  disabled: boolean;
  onChange: (label: string, relType: RelationshipTypes) => void;
}

interface IdeaRelationshipMenuPickerState {
  anchorEle: HTMLElement | null;
  selectedIndex: number;
}

export class IdeaRelationshipMenuPicker extends Component<IdeaRelationshipMenuPickerProps, IdeaRelationshipMenuPickerState> {
  state: IdeaRelationshipMenuPickerState = {
    anchorEle: null,
    selectedIndex: 1
  };

  constructor(props: IdeaRelationshipMenuPickerProps) {
    super(props);
    // MAYBE: issues with selected prop being re-defined after mounting.
    this.state.selectedIndex = options.indexOf(props.selected);
  }

  public handleClickListItem(event: MouseEvent<HTMLElement>): void {
    if (!(this.props.disabled)) {
      this.setState({
        anchorEle: event.currentTarget
      });
    }
  }

  public handleMenuItemClick(_event: MouseEvent<HTMLElement>, index: number): void {
    this.setState({
      selectedIndex: index,
      anchorEle: null
    });
    this.props.onChange(this.props.label, options[index]);
  }

  public handleClose(): void {
    this.setState({
      anchorEle: null
    });
  }

  public render(): ReactNode {
    const open = Boolean(this.state.anchorEle);

    return (
      <div>
        <List
          component="nav"
          aria-label="Device settings"
          sx={{ bgcolor: 'background.paper' }}
        >
          <ListItem
            button
            id={`${this.props.idPrefix}-button`}
            aria-haspopup="listbox"
            aria-controls={`${this.props.idPrefix}-menu`}
            aria-label={this.props.label}
            aria-expanded={open ? 'true' : undefined}
            disabled={this.props.disabled}
            onClick={this.handleClickListItem.bind(this)}
          >
            <ListItemText
              primary={this.props.label}
              secondary={options[this.state.selectedIndex]}
            />
          </ListItem>
        </List>
        <Menu
          id={`${this.props.idPrefix}-menu`}
          anchorEl={this.state.anchorEle}
          open={open}
          onClose={this.handleClose.bind(this)}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              disabled={index === 0}
              selected={index === this.state.selectedIndex}
              onClick={(event) => this.handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default IdeaRelationshipMenuPicker;
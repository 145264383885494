import React, { Component, MouseEvent } from 'react';
import { css } from '@emotion/react';

import Popover from '@mui/material/Popover';

import LinkIcon from '@mui/icons-material/Link';

import { IdeaPageRelationshipsType } from './IdeaPage';
import { RelationshipTypes } from './IdeaModel';
import IdeaRelationshipMenuPicker from './IdeaRelationshipMenuPicker';

export interface IdeaRelationshipsProps {
    iconCss: SerializedStyles;
    parent: string;
    relationships: IdeaPageRelationshipsType;
    disabled: boolean;
    onChange: (label: string, relType: RelationshipTypes) => void;
    onPopoverToggle: (open: boolean) => void;
}

interface IdeaRelationshipsState {
    anchorEl: HTMLElement | null;
    changes: Array<string>;
}

export class IdeaRelationships extends Component<IdeaRelationshipsProps, IdeaRelationshipsState> {
    state: IdeaRelationshipsState = {
        anchorEl: null,
        changes: []
    }

    public onChange(label: string, relType: RelationshipTypes): void {
        let newChanges = this.state.changes.map((e) => e);
        if (this.props.relationships[label] != relType) {
            newChanges.push(label);
            this.setState({
                changes: newChanges
            });
        } else {
            const indexOf = newChanges.indexOf(label);
            if (indexOf > -1) {
                newChanges.splice(indexOf, 1);
                this.setState({
                    changes: newChanges
                })
            }
        }
    }

    public handleClick(event: MouseEvent<HTMLElement>): void {
        this.setState({
            anchorEl: event.currentTarget
        });
        this.props.onPopoverToggle(true);
    }

    public handleClose(): void {
        this.setState({
            anchorEl: null
        });
        this.props.onPopoverToggle(false);
    }

    public haveChanges(): boolean {
        return (this.state.changes.length > 0);
    }

    public numUnknowns(): number {
        const pageNames = Object.keys(this.props.relationships);
        return pageNames.reduce((acc, name) => {
            if (this.props.relationships[name] === RelationshipTypes.unknown) acc = acc + 1;
            return acc;
        }, 0);
    }

    public haveUnknowns(): boolean {
        return (this.numUnknowns() > 0);
    }

    public render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'relationships-popover' : undefined;

        const haveUnknowns = this.haveUnknowns();

        const iconColor = haveUnknowns ? 'action' : 'inherit';
        const iconBackgroundColor = haveUnknowns ? 'red' : 'white';
        const relNames = Object.keys(this.props.relationships);
        return (<div
            css={this.props.iconCss}
        >
            <LinkIcon
                // color={iconColor}
                onClick={this.handleClick.bind(this)}
                sx={{ color: iconColor, backgroundColor: iconBackgroundColor, borderRadius: "50%" }}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose.bind(this)}
            >
                {relNames.length > 0 ?
                relNames.map((name) => (
                    <IdeaRelationshipMenuPicker key={name} label={name} idPrefix={`${this.props.parent}-${name}`} selected={this.props.relationships[name]} disabled={this.props.disabled} onChange={this.props.onChange} />
                )) : <span>No relationships found.</span> }
            </Popover>
        </div>)
    }
}


import { MouseEvent } from 'react';

import { downloadFile, uploadFile } from './downloadFile';
import { ExportPagesType, exportPages, importPages } from './IdeaModel';
import { openIFrame } from './openIFrame';

export const performLink = async (e: MouseEvent<SVGSVGElement>): Promise<void> => {
    e.preventDefault();
    const target = 'https://ideapad.forge.io/';

    const iframe = await openIFrame(target);
    iframe.onload = () => {
        console.log('iframe loaded, posting message: initiateContact');
        iframe.contentWindow.postMessage('initiateContact', target);
    };
    iframe.contentWindow.addEventListener('onload', () => {
        console.log('iframe.contentWindow loaded, posting message: initiateContact');
        iframe.contentWindow.postMessage('initiateContact', target);
    });
};

export const exportToJson = async (e: MouseEvent<SVGSVGElement>): Promise<void> => {
    const data = await exportPages();
    e.preventDefault()
    downloadFile({
        data: JSON.stringify(data),
        fileName: 'data.json',
        fileType: 'text/json',
    })
}

export const importFromJson = (e: MouseEvent<SVGSVGElement>): Promise<boolean> => new Promise((resolve) => {
    e.preventDefault();
    try {
        uploadFile(async (str: string): Promise<void> => {
            try {
                const data: ExportPagesType = JSON.parse(str);
                await importPages(data);
                resolve(true);
            } catch (e) {
                console.error(`Failed to parse JSON.`);
                console.error(e);
                resolve(false);
            }
        });
    } catch (e) {
        console.error(`Failed to import JSON.`);
        console.error(e);
        resolve(false);
    }
});
import React, { Component, MouseEvent, RefObject, createRef } from 'react';
import { css } from '@emotion/react';
import { Paper } from '@mui/material';
import Alert from '@mui/material/Alert';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';

import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import PrepareHtml, { ExtractLinks } from './PrepareHtml';

import IdeaModel, { RelationshipTypes } from './IdeaModel';
import IdeaDiagram from './IdeaDiagram';
import { IdeaRelationships } from './IdeaRelationships';
import { exportToJson, importFromJson } from './IdeaPageHandlers';

export interface IdeaPageProps {
    title: string;
}

export type IdeaPageRelationshipsType = {
    [key: string]: RelationshipTypes
}

export interface IdeaPageState {
    loading: boolean;
    loaded: boolean;
    changed: boolean;
    editMode: boolean;
    alertMessage: string;
    statusMessage: string;
    lastTitle: string;
    description: string;
    content: string;
    relationships: IdeaPageRelationshipsType;
}

export class IdeaPage extends Component<IdeaPageProps, IdeaPageState> {
    state: IdeaPageState = {
        loading: true,
        loaded: false,
        changed: false,
        editMode: false,
        alertMessage: '',
        statusMessage: '',
        lastTitle: '',
        description: '',
        content: '',
        relationships: {}
    }
    private model: IdeaModel;
    private pending: {
        description: string;
        content: string;
    }
    private ideaRelationshipsRef: RefObject<IdeaRelationships>;

    constructor(props: IdeaPageProps) {
        super(props);
        window.addEventListener('beforeunload', this.beforeUnload.bind(this));
        // window.addEventListener('message', this.messageHandler.bind(this));
        this.ideaRelationshipsRef = createRef<IdeaRelationships>();
        this.model = new IdeaModel(props.title);
        this.state.lastTitle = props.title;
        this.pending = {
            description: '',
            content: ''
        };
        this.state.editMode = false;
        this.state.relationships = {};
        this.loadModel();
    }

    public hasChanges(): boolean {
        return this.state.changed || (this.ideaRelationshipsRef.current && this.ideaRelationshipsRef.current.haveChanges());
    }

    private loadModel(): void {
        this.model.load().then((result) => {
            if (result === true) {
                this.pending.description = this.model.description;
                this.pending.content = this.model.content;
                const relationships: IdeaPageRelationshipsType = {};
                const connectionNames = Object.keys(this.model.connections);
                for (let c = 0; c < connectionNames.length; c++) {
                    const connectionName = connectionNames[c];
                    relationships[connectionName] = this.model.connections[connectionName].type;
                }
                this.setState({
                    description: this.model.description,
                    content: this.model.content,
                    loaded: true,
                    loading: false,
                    changed: false,
                    relationships
                });
            } else {
                this.setState({
                    loading: false,
                    content: '',
                    description: '',
                    changed: false,
                    loaded: false,
                    relationships: {}
                });
            }
        });
    }

    public toggleEditMode(): void {
        this.setState({ editMode: !(this.state.editMode) });
    }

    public relationshipsToggled(open: boolean): void {
        if (open) this.setState({ editMode: true });
    }

    public descriptionBlurred(_event: FocusEvent): void {
        const sanitizedDescription = PrepareHtml(this.pending.description);
        if (sanitizedDescription !== this.state.description) {
            const relationships = ExtractLinks(sanitizedDescription);
            Object.assign(relationships, ExtractLinks(this.state.content));
            const names = Object.keys(this.state.relationships);
            for (let n = 0; n < names.length; n++) {
                const name = names[n];
                if (name in relationships) relationships[name] = this.state.relationships[name];
            }
            this.setState({
                description: sanitizedDescription,
                changed: true,
                relationships
            });
        }
    }

    public descriptionChanged(event: ContentEditableEvent): void {
        this.pending.description = event.target.value;
    }

    public contentChanged(event: ContentEditableEvent): void {
        this.pending.content = event.target.value;
    };

    public contentBlurred(_event: FocusEvent): void {
        const sanitizedContent = PrepareHtml(this.pending.content);
        if (sanitizedContent !== this.state.content) {
            const relationships = ExtractLinks(this.state.description);
            Object.assign(relationships, ExtractLinks(sanitizedContent));
            const names = Object.keys(this.state.relationships);
            for (let n = 0; n < names.length; n++) {
                const name = names[n];
                if (name in relationships) relationships[name] = this.state.relationships[name];
            }
            this.setState({
                content: sanitizedContent,
                changed: true,
                relationships
            });
        }
    }

    public relationshipChanged(name: string, relType: RelationshipTypes): void {
        if (this.state.relationships[name] != relType) {
            const relationships = Object.assign({}, this.state.relationships);
            relationships[name] = relType;
            this.setState({
                relationships,
                changed: true
            });
        }
    }

    public async saveClicked() {
        this.model.description = this.state.description;
        this.model.content = this.state.content;
        const pageNames = Object.keys(this.state.relationships);
        let existingPageName = Object.keys(this.model.connections);
        for (let p = 0; p < pageNames.length; p++) {
            const pageName = pageNames[p];
            const pageType = this.state.relationships[pageName];
            if (!(pageName in this.model.connections)) {
                this.model.connections[pageName] = {
                    model: new IdeaModel(pageName),
                    type: pageType,
                }
            } else {
                this.model.connections[pageName].type = pageType;
                existingPageName.splice(existingPageName.indexOf(pageName), 1);
            }
        }
        if (existingPageName.length > 0) {
            for (let i = 0; i < existingPageName.length; i++) {
                console.log('delete ' + existingPageName[i]);
                delete this.model.connections[existingPageName[i]];
            }
        }
        const result = await this.model.save();
        if (!result) {
            this.setState({ alertMessage: 'Unable to save model!' });
        } else {
            this.setState({ changed: false, editMode: false, statusMessage: 'Saved successfully.' });
        };
    };

    // public messageHandler(event: MessageEvent): void {
        // if (event.data.source && ['react-devtools-content-script', 'react-devtools-inject-backend', 'react-devtools-bridge'].indexOf(event.data.source) === -1) {
    //    console.log('got message: ');
    //    console.dir(event);
        // }
    // } 

    public componentWillUnmount(): void {
        window.removeEventListener('beforeunload', this.beforeUnload.bind(this));
        // window.removeEventListener('message', this.messageHandler.bind(this));
    }

    public beforeUnload(event: BeforeUnloadEvent) {
        if (this.state.changed) {
            event.preventDefault();
            event.returnValue = 'There are unsaved changes are you sure you want to leave?';
        }
        return event;
    }

    public render() {
        if (this.props.title !== this.state.lastTitle) {
            this.setState({ lastTitle: this.props.title });
            this.model = new IdeaModel(this.props.title);
            this.loadModel();
        }

        if (this.state.statusMessage.length > 0) {
            setTimeout(() => {
                this.setState({ statusMessage: '' });
            }, 3500);
        }

        const iconCss = css`
            float: right;
            padding-left: 10px;
            border-radius: 5px;
        `;

        const alertCss = css`
            float: right;
        `;

        const editCss = css`
            width: 100%;
            min-height: 2em;
            border-radius: 2px;
            ${this.state.editMode ? 'border: 1px solid red;' : 'padding: 1px;'};
        `

        return <Paper css={css`
                min-width: 200px;
                width: 90%;
                max-width: 1080px;
                min-height: 200px;
                height: 100%;
                margin: 2em;
                padding: 1em;
            `}
        >
            <section>
            <HomeIcon
                css={iconCss}
                onClick={() => (window.location.hash = '')}
            />
            <SaveIcon
                css={iconCss}
                color={this.state.changed ? 'action' : 'disabled'}
                onClick={this.saveClicked.bind(this)}
            />
            <EditIcon
                css={iconCss}
                color={this.state.editMode ? 'action' : 'disabled'}
                onClick={this.toggleEditMode.bind(this)}
            />
            <IdeaRelationships
                iconCss={iconCss}
                ref={this.ideaRelationshipsRef}
                parent={this.props.title}
                relationships={this.state.relationships}
                disabled={!(this.state.editMode)}
                onChange={this.relationshipChanged.bind(this)}
                onPopoverToggle={this.relationshipsToggled.bind(this)}
            />
            <FileDownloadIcon
                css={iconCss}
                onClick={exportToJson}
            />
            <FileUploadIcon
                css={iconCss}
                onClick={async (e) => {
                    const result = await importFromJson(e);
                    if (result) {
                        this.model.forceLoad();
                        this.loadModel();
                        this.setState({ statusMessage: 'Imported JSON data successfully.' });
                    } else {
                        this.setState({ alertMessage: 'Failed to import JSON data successfully.' });
                    }
                }
                }
            />

            {(this.state.alertMessage.length > 0) && (
                <Alert
                    css={alertCss} variant='filled' severity='error'
                    onClose={() => {this.setState({ alertMessage: '' }); }}
                >{this.state.alertMessage}</Alert>
            )}
            {(this.state.statusMessage.length > 0) && (
                <Alert
                    css={alertCss} variant='filled' severity='success'
                    onClose={() => { this.setState({ statusMessage: '' }); }}
                >{this.state.statusMessage}</Alert>
            )}
            </section>
            <section>
            <h1>{this.props.title}</h1>
            {(!(this.state.loading)) && (<>
                <ContentEditable
                    css={editCss}
                    html={this.state.description || `Please describe ${this.props.title}`}
                    onChange={this.descriptionChanged.bind(this)}
                    onBlur={this.descriptionBlurred.bind(this)}
                    disabled={!(this.state.editMode)}
                />
                <IdeaDiagram title={this.props.title} model={this.model} />
                <ContentEditable
                    css={editCss}
                    html={this.state.content || `Please write content on ${this.props.title}`}
                    onChange={this.contentChanged.bind(this)}
                    onBlur={this.contentBlurred.bind(this)}
                    disabled={!(this.state.editMode)}
                />
            </>
            )}
        </section>
        </Paper>;
    }
}

export default IdeaPage;
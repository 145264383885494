export const downloadFile = ({ data , fileName, fileType }: { data: string, fileName: string, fileType: string }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
}

export const uploadFile = (callback: (data: string) => void): void => {

    const input = document.createElement('input');
    input.type = 'file';
    // @ts-ignore
    const readFile = (event) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onload = () => {
            callback(fileReader.result.toString());
            input.remove();
        };
        fileReader.onerror = (e) => {
            console.error('fileReader error: ');
            console.dir(e);
            input.remove();
        };
    };
    // @ts-ignore
    input.onchange = readFile;
    // @ts-ignore
    input.onabort = () => {
        input.remove();
    };

    input.click();
});
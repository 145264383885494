
import { Storage } from '@capacitor/storage';

type IdeaDataModelConnection = [string, RelationshipTypes];
type IdeaDataModelConnections = Array<IdeaDataModelConnection>;

export enum RelationshipTypes {
    parent = 'Parent',
    child = 'Child',
    sibling = 'Sibling',
    unknown = 'Unknown'
}

export interface IdeaDataModel {
    title: string;
    description: string;
    content: string;
    connections: IdeaDataModelConnections;
}

export type IdeaModelConnections = {
    [key: string]: {
        model: IdeaModel;
        type: RelationshipTypes;
    }
}

function connectionsModelToObject(connections: IdeaDataModelConnections): IdeaModelConnections {
    const output: IdeaModelConnections = {};
    for(let c = 0; c < connections.length; c++) {
        const [ title, typeString ] = connections[c];
        output[title] = {
            model: new IdeaModel(title),
            type: typeString,
        }
    }
    return output;
}

function connectionsObjectToModel(connections: IdeaModelConnections): IdeaDataModelConnections {
    const output: IdeaDataModelConnections = [];
    const connectionTitles = Object.keys(connections);
    for(let c = 0; c < connectionTitles.length; c++) {
        const title = connectionTitles[c];
        const relType = connections[title].type;
        output.push([title, relType]);
    }
    return output;
}

const IdeaModelObjectCache: { [key: string]: IdeaModel } = {

};

export const clearCache = () => {
    const pageNames = Object.keys(IdeaModelObjectCache);
    for(let p = 0; p < pageNames.length; p++) {
        const name = pageNames[p];
        delete IdeaModelObjectCache[name];
    }
};

export const clearPages = async (): Promise<void> => {
    await Storage.clear();
}

export type ExportPagesType = {
    [key: string]: string
}

export const exportPages = async (): Promise<ExportPagesType> => {
    const output: ExportPagesType = {};
    const keys = await Storage.keys();
    for(let k = 0; k < keys.keys.length; k++) {
        const key = keys.keys[k];
        output[key] = (await Storage.get({ key })).value;
    }
    return output;
}

export const importPages = async (input: ExportPagesType): Promise<void> => {
    clearCache();
    await clearPages();
    const pageNames = Object.keys(input);
    for(let p = 0; p < pageNames.length; p++) {
        const name = pageNames[p];
        await Storage.set({ key: name, value: input[name] });
    }
}

export class IdeaModel {
    public title: string;
    public isLoaded: boolean | null;
    public connections: IdeaModelConnections;
    public description: string;
    public content: string; // TODO something richer? markdown?

    constructor(title: string) {
        this.title = title;
        this.connections = {};
        if (this.title in IdeaModelObjectCache) {
            return IdeaModelObjectCache[this.title];
        }
        IdeaModelObjectCache[this.title] = this;
        this.isLoaded = null;

    }

    public forceLoad(): Promise<boolean> {
        this.isLoaded = false;
        return this.load();
    }

    public load(): Promise<boolean> {
        return new Promise(async (resolve, _reject) => {
            if (this.isLoaded) {
                return resolve(true);
            }
            const recordValue = (await Storage.get({ key: this.title })).value;
            if (recordValue === null) {
                this.isLoaded = false;
                return resolve(false);
            }
            this.fromString(recordValue);
            this.isLoaded = true;
            return resolve(true);
        });
    }

    public save(childSave: boolean = false): Promise<boolean> {
        return new Promise(async (resolve, _reject) => {
            if (!(this.isLoaded)) {
                await this.load();
            }
            await Storage.set({ key: this.title, value: this.toString() });
            if (childSave === false) {
                const names = Object.keys(this.connections);
                for(let n = 0; n < names.length; n++) {
                    const rel = this.connections[names[n]];
                    let otherRelType: RelationshipTypes | null = null;
                    switch (rel.type) {
                        case RelationshipTypes.child:
                            otherRelType = RelationshipTypes.parent;
                            break;
                        case RelationshipTypes.parent:
                            otherRelType = RelationshipTypes.child;
                            break;
                        case RelationshipTypes.sibling:
                            otherRelType = RelationshipTypes.sibling;
                            break;
                        case RelationshipTypes.unknown:
                        default:
                            break;
                    }
                    if (otherRelType) {
                        if (!(this.title in rel.model.connections)) {
                            rel.model.connections[this.title] = {
                                model: this,
                                type: otherRelType
                            }
                        } else {
                            rel.model.connections[this.title].type = otherRelType;
                        }
                        await rel.model.save(true);
                    }
                }
            }
            resolve(true);
        });
    }

    public fromString(str: string): void {
        const record: IdeaDataModel = JSON.parse(str);
        this.description = record.description;
        this.content = record.content;
        this.connections = connectionsModelToObject(record.connections);
    }

    public toString(): string {
        return JSON.stringify({
            title: this.title,
            description: this.description,
            content: this.content,
            connections: connectionsObjectToModel(this.connections)
        })
    }
}

export default IdeaModel;



import sanitizeHtml, { IOptions } from 'sanitize-html';
import { RelationshipTypes } from './IdeaModel';
import { IdeaPageRelationshipsType } from './IdeaPage';

const sanitizeHtmlConfig: IOptions = {
    allowedTags: ['div', 'b', 'i', 'em', 'br', 'strong', 'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h6', 'h7'],
    allowedAttributes: { a: ['href', 'onclick'] }
};

const PascelCaseRegexp = /((([A-Z][A-Za-z0-9]+)([A-Z][A-Za-z0-9]+)+)|\[([A-Z][a-z0-9]+)\])/;

const callOnEveryChildNode = (eles: HTMLElement) => {
    for (let e = 0; e < eles.childNodes.length; e++) {
        const ele = eles.childNodes[e] as HTMLElement;
        switch (ele.tagName) {
            case 'BR':
            case 'A':
                // ignore
                break;
            case undefined:
            default:
                if (ele.childNodes.length === 0) {
                    if (ele.nodeName === '#text') {
                        const newHtml = replacePascelCase(ele.textContent);
                        if (newHtml != ele.textContent) {
                            const newDiv = document.createElement('div');
                            newDiv.innerHTML = newHtml;
                            ele.parentElement.replaceChild(newDiv, ele);
                        }
                    } else {
                        ele.innerHTML = replacePascelCase(ele.innerHTML);
                    }
                } else {
                    callOnEveryChildNode(ele);
                };
        }
    }
}

const replacePascelCase = (input: string): string => {
    let output = '';
    let remaining = input;
    let reMatch = PascelCaseRegexp.exec(remaining);
    while (reMatch) {
        const match = (reMatch[5]) ? reMatch[5] : reMatch[0];
        if (reMatch.index > 0) output += remaining.substring(0, reMatch.index);
        output += `<a href='#/idea/${match}'>${match}</a>`;
        remaining = remaining.substring(reMatch.index + reMatch[0].length);
        reMatch = PascelCaseRegexp.exec(remaining);
    }
    if (remaining.length > 0) output += remaining;
    return output;
};

export const PrepareHtml = (input: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    callOnEveryChildNode(doc.body);
    return sanitizeHtml(doc.body.innerHTML, sanitizeHtmlConfig);
}

export const ExtractLinks = (input: string): IdeaPageRelationshipsType => {
    const output: IdeaPageRelationshipsType = {};
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    const aEles = doc.getElementsByTagName('A');
    for(let a = 0; a < aEles.length; a++) {
        const ele = aEles[a];
        output[ele.textContent] = RelationshipTypes.unknown;
    }
    return output;
}

export default PrepareHtml;
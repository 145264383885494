import React, { Component, RefObject, createRef } from 'react';
import { Storage } from '@capacitor/storage';

import IdeaPage from './IdeaPage';

export class App extends Component<{}, {}> {
    private locationWatcher: number;
    private lastLocation: string;
    private pageRef: RefObject<IdeaPage>;

    state = {
        pageType: 'idea',
        currentPage: 'HomePage'
    }

    constructor(props: {}) {
        console.log(`App: ${window.document.location.hostname}`);
        super(props);
        this.pageRef = createRef<IdeaPage>();
        Storage.configure({ group: 'Ideas' });
        this.lastLocation = App.currentHash();
        if (this.lastLocation !== '') {
            const parts = this.lastLocation.split('/');
            this.state.pageType = parts[1];
            this.state.currentPage = parts[2];
        }
    }

    static currentHash(): string {
        return window.location.hash.slice(1);
    }

    private startLocationHashWatcher(): void {
        this.locationWatcher = window.setInterval(() => {
            const currentHash = App.currentHash();
            if (currentHash !== this.lastLocation) {
                const allowed = this.pageRef.current.hasChanges() ? window.confirm('There are unsaved changes are you sure you want to continue?') : true;
                if (allowed) {
                    this.lastLocation = currentHash;
                    if (currentHash.length === 0) {
                        this.setState({
                            pageType: 'idea',
                            currentPage: 'HomePage'
                        });
                    } else {
                        const parts = currentHash.split('/');
                        this.setState({
                            currentPage: parts[2],
                            pageType: parts[1]
                        });
                    }
                } else {
                    window.location.hash = '#' + this.lastLocation;
                }
            }
        }, 50);
    }

    public componentDidMount(): void {
        this.startLocationHashWatcher();
    }

    public componentWillUnmount(): void {
        clearInterval(this.locationWatcher);
    }

    public render() {
        /* const location = useLocation();
        useEffect(() => {
            console.log('location changed: ');
            console.dir(location);
        }, [location]); */
        return <IdeaPage ref={this.pageRef} title={this.state.currentPage}/>;
    }
}

export default App;
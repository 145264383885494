import React, { Component, createRef, RefObject } from 'react';
import { css } from '@emotion/react';
import IdeaModel from './IdeaModel';

export interface IdeaBubbleProps {
    title: string;
}

export class IdeaBubble extends Component<IdeaBubbleProps> {

}

export interface IdeaDiagramProps {
    title: string;
    model: IdeaModel;
}


export class IdeaDiagram extends Component<IdeaDiagramProps> {
    private myRef: RefObject<SVGSVGElement>;

    constructor(props: IdeaDiagramProps) {
        super(props);
        this.myRef = createRef<SVGSVGElement>();
    }

    public componentDidMount(): void {
        const rect = this.myRef.current.getBoundingClientRect();
        // console.dir(rect);
    }

    public render() {
        const { model } = this.props;

        const c1 = function () {
            console.log('c1 clicked');
        };

        return <svg
                ref={this.myRef}
                css={css`
                  width: 100%;
                  height: 100%;
                  min-height: 300px;
                `}
            >
            <circle onClick={c1} cx='50' cy='50' r='40' stroke='green' strokeWidth='4' fill='red' />
            <polygon 
                points='50 160 55 180 70 180 60 190 65 205 50 195 35 205 40 190 30 180 45 180'
                stroke='green' 
                fill='transparent' 
                strokeWidth='5'
            />
        </svg>;
    }
}

export default IdeaDiagram;